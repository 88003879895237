// import { useIntl } from "gatsby-plugin-intl"
// import useGetCountry from "./useGetCountry";

export default function useCompany(country = "both") {
  // const intl = useIntl()
  // // const { country: { code } } = useGetCountry();

  // const MX = true;
  // // const MX = code === "MX";
  // // const CO = code === "CO";

  // const directionMX = "Mariano Escobedo 476, Anzures. C.P.11590 CDMX";
  // const companyMX = MX
  //   ? `${intl.formatMessage({ id: "company-legend" })} KXNETCOM S.A. de C.V. ${directionMX}`
  //   : null;
  // // const companyCO = CO ? `${t("company")} Marketing Group CO S.A.S` : null;

  // // if (country === "both") return companyCO ?? companyMX ?? "";
  // // if (country === "CO") return companyCO ?? "";
  return  "";
}