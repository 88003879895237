import React from "react";
import PropTypes from "prop-types"

import "../styles/General.scss"

import Header from "./Header.jsx"
import Footer from "./Footer.jsx"

const Layout = ({ children }) => {
  //   useEffect(() => {
  //     const script = document.createElement("script");
  //     script.type = "text/javascript";
  //     script.textContent = `
  // function initFreshChat() {
  //   window.fcWidget.init({
  //     token: "47da0fbc-8e45-4858-9857-0ba827c735fd",
  //     host: "https://wchat.freshchat.com"
  //   });
  // }
  // function initialize(i,t){
  //   var e;
  //   i.getElementById(t) ? initFreshChat() :
  //     ((e=i.createElement("script")).id=t,
  //     e.async=!0,e.src="https://wchat.freshchat.com/js/widget.js",
  //     e.onload=initFreshChat,i.head.appendChild(e))
  // }
  // function initiateCall() {
  //   initialize(document,"freshchat-js-sdk")
  // }
  // window.addEventListener ?
  // window.addEventListener("load",initiateCall,!1):
  // window.attachEvent("load",initiateCall,!1);
  // `;
  //     const head = document.querySelector("head");
  //     head.appendChild(script);
  //     return () => {
  //       head.removeChild(script);
  //     };
  //   }, []);

  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
