import React, { useEffect, useState } from "react"
import {
  useIntl,
  Link,
  IntlContextConsumer,
  changeLocale,
} from "gatsby-plugin-intl"
import config from "../config"

import "../styles/components/Header.scss"

import Logo from "../assets/images/icons/logo.svg"
import LogoMobile from "../assets/images/icons/Logo_mobile.svg"
import AboutIcon from "../assets/images/icons/about-icon.svg"
import MarquetIcon from "../assets/images/icons/marquet-icon.svg"
import PlatformIcon from "../assets/images/icons/platform-icon.svg"
// import EducationIcon from "../assets/images/icons/education-icon.svg"
import FaqIcon from "../assets/images/icons/faq-icon.svg"
// import SupportIcon from "../assets/images/icons/support-icon.svg"
import MenuIcon from "../assets/images/icons/menu-icon.svg"
import LanguageIcon from "../assets/images/icons/language-icon.svg"

export default function Header() {
  const intl = useIntl()
  const [menu, setMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);

  const handleChangeLanguage = lang => () => {
    changeLocale(lang === "en" ? "es" : "en")
  }

  const handleShowMenu = () => {
    setMenu(!menu)
  }

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth)
    };
    handleResize();

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, []);

  return (
    <header className="header">
      <Link to="/" className="header__logo-container">
        <img
          src={screenWidth > 535 ? Logo : LogoMobile}
          alt=""
          className="header__logo"
        />
      </Link>
      <ul className="header__list">
        {screenWidth > 535 &&
          <>
            <li className="header__list-item">
              <a
                className="header__list-item-link"
                href={config.signin}
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({ id: "header-link-one" })}
              </a>
            </li>
            <li className="header__list-item">
              <Link to="/register" className="header__list-item-link">
                {intl.formatMessage({ id: "header-link-two" })}
              </Link>
            </li>
          </>}
        <li className="header__list-item">
          <IntlContextConsumer>
            {({ language }) => (
              <div className="language">
                <img src={LanguageIcon} alt="" className="language__icon" />
                {language}
                <div className="language__menu">
                  <button
                    onClick={handleChangeLanguage(language)}
                    className="language__menu-button"
                    type="button"
                    disabled={language === "es"}
                  >
                    ES
                  </button>
                  <button
                    onClick={handleChangeLanguage(language)}
                    className="language__menu-button"
                    type="button"
                    disabled={language === "en"}
                  >
                    EN
                  </button>
                </div>
              </div>
            )}
          </IntlContextConsumer>
        </li>
        <li className="header__list-item">
          <button
            onClick={handleShowMenu}
            type="button"
            className="header__action"
          >
            <img src={MenuIcon} alt="" className="header__action-icon" />
          </button>
        </li>
      </ul>
      <nav className={`header__nav${menu ? " header__nav--active" : ""}`}>
        <ul className="header__nav-list">
          {screenWidth < 535 &&
            <>
              <li className="header__nav-item">
                <a
                  className="header__list-item-link"
                  href={config.signin}
                  target="_blank"
                  rel="noopener noreferrer"
              >
                  {intl.formatMessage({ id: "header-link-one" })}
                </a>
              </li>
              <li className="header__nav-item">
                <a
                  className="header__list-item-link"
                  href="/register"
                >
                  {intl.formatMessage({ id: "header-link-two" })}
                </a>
              </li>
            </>
          }
          <li className="header__nav-item">
            <Link
              onClick={handleShowMenu}
              to="/about"
              className="header__nav-item-link"
            >
              <img src={AboutIcon} alt="" className="header__nav-item-icon" />
              {intl.formatMessage({ id: "header-link-three" })}
            </Link>
          </li>
          <li className="header__nav-item">
            <Link
              onClick={handleShowMenu}
              to="/market"
              className="header__nav-item-link"
            >
              <img src={MarquetIcon} alt="" className="header__nav-item-icon" />
              {intl.formatMessage({ id: "header-link-four" })}
            </Link>
          </li>
          <li className="header__nav-item">
            <Link
              onClick={handleShowMenu}
              to="/platform"
              className="header__nav-item-link"
            >
              <img
                src={PlatformIcon}
                alt=""
                className="header__nav-item-icon"
              />
              {intl.formatMessage({ id: "header-link-five" })}
            </Link>
          </li>
          {/*           <li className="header__nav-item">
            <Link
              onClick={handleShowMenu}
              to="/register"
              className="header__nav-item-link"
            >
              <img
                src={EducationIcon}
                alt=""
                className="header__nav-item-icon"
              />
              {intl.formatMessage({ id: "register-title" })}
            </Link>
          </li> */}
          <li className="header__nav-item">
            <Link
              onClick={handleShowMenu}
              to="/support"
              className="header__nav-item-link"
            >
              <img src={FaqIcon} alt="" className="header__nav-item-icon" />
              {intl.formatMessage({ id: "header-link-seven" })}
            </Link>
          </li>
          {/* <li className="header__nav-item">
            <a
              onClick={handleShowMenu}
              href={config.support}
              target="_blank"
              rel="noopener noreferrer"
              className="header__nav-item-link"
            >
              <img src={SupportIcon} alt="" className="header__nav-item-icon" />
              {intl.formatMessage({ id: "header-link-eight" })}
            </a>
          </li> */}
        </ul>
      </nav>
    </header>
  )
}
