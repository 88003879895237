import React from "react"
import { useIntl, Link } from "gatsby-plugin-intl"

import "../styles/components/Footer.scss"

import Logo from "../assets/images/icons/logo.svg"
import useCompany from "../hooks/useCompany"

export default function Footer() {
  const intl = useIntl();
  const company = useCompany();

  return (
    <footer className="footer">
      <div className="footer__nav">
        <img src={Logo} alt="" className="footer__logo" />
        <ul className="footer__nav-list">
          <li className="footer__nav-list-item">
            <Link to="/about" className="footer__link">
              {intl.formatMessage({ id: "footer-link-one" })}
            </Link>
          </li>
           <li className="footer__nav-list-item">
            <Link to="/register" className="footer__link">
              {intl.formatMessage({ id: "register-title" })}
            </Link>
          </li> 
          <li className="footer__nav-list-item">
            <Link to="/market" className="footer__link">
              {intl.formatMessage({ id: "footer-link-three" })}
            </Link>
          </li>
          <li className="footer__nav-list-item">
            <Link to="/platform" className="footer__link">
              {intl.formatMessage({ id: "footer-link-four" })}
            </Link>
          </li>
          <li className="footer__nav-list-item">
            <Link to="/terms" className="footer__link">
              {intl.formatMessage({ id: "footer-link-five" })}
            </Link>
          </li>
          {/* <li className="footer__nav-list-item">
            <a
              href={config.support}
              target="_blank"
              rel="noopener noreferrer"
              className="footer__link"
            >
              {intl.formatMessage({ id: "header-link-eight" })}
            </a>
          </li> */}
        </ul>
      </div>
      <span className="footer__legend">
        {intl.formatMessage({ id: "footer-legend" })}
        {company}
      </span>
    </footer>
  )
}
